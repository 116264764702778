
import { defineComponent } from "vue";
import BaseHeader1 from "@/components/BaseHeader1.vue";
import InputBox from "@/components/member/InputBox.vue";
import CheckBox from "@/components/common/CheckBox.vue";
import DefaultButton from "@/components/common/DefaultButton.vue";
import { email, required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import { TokenModel } from "@/store/member";
import { useStore } from "vuex";

export default defineComponent({
  name: "Home",
  components: { BaseHeader1, InputBox, CheckBox, DefaultButton },
  setup: () => ({ v$: useVuelidate() }),
  data() {
    const store = useStore();
    return {
      redirectTo: "/",
      store,
      email: "",
      password: "",
      autoLogin: true,
    };
  },
  validations: {
    email: { required, email },
    password: { required },
  },
  methods: {
    loginKakao() {
      this.$flutter.callHandler("loginKakao");
    },
    signin() {
      this.$axios
        .post("/token", {
          email: this.email,
          password: this.password,
          remember: this.autoLogin,
        })
        .then((res) => {
          this.$pushGAEvent("login", {
            method: "email",
          });
          const data: TokenModel = res.data;
          this.email = "";
          this.password = "";

          this.$injectToken(data.access);
          this.store.dispatch("member/updateToken", data).then(() => {
            this.$flutter.callHandler("updateToken", {
              access: data.access,
              refresh: data.refresh,
            });
          });
          if (this.redirectTo === "/") {
            this.$flutter.callHandler("selectGnb", {
              path: "/",
            });
          } else {
            this.$router.replace(this.redirectTo);
          }
        })
        .catch((error) => {
          let errMsg = "";
          const data = error.response.data;
          if (data && data.detail == "ban_account") {
            errMsg = "사용이 정지된 계정입니다.";
          } else if (error.response.status == 401) {
            errMsg = "이메일 또는 비밀번호가 일치하지 않습니다.";
          }
          if (errMsg) {
            this.$flutter.callHandler("showToast", {
              message: errMsg,
              color: "error",
            });
          }
        });
    },
  },
  created() {
    this.$flutter.callHandler("hideGnb");
  },
  beforeUnmount() {
    this.$flutter.callHandler("showGnb");
  },
  mounted() {
    this.$pushGAEvent("login_try");
    const path = this.$route.query as { redirect: string } | null;
    if (path && path?.redirect) {
      this.redirectTo = path.redirect;
    }
  },
});
